import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input
} from 'reactstrap';
import DayPickerInput from '../lib-wrappers/DayPickerInput';
import { generateTimeArr } from '@b2w/shared/dates';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import NewAutoComplete from '../gmaps/NewAutoComplete';
import Head from 'next/head';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { date, mixed, object, string } from 'yup';
import Router from 'next/router';
import {
  isSameDate,
  formatDate,
  templates,
  toISOLocal
} from '@/lib/dayjs.common';
import dayjs from 'dayjs';
import {
  calculateAroundRadius,
  getZoomLevelFromBounds
} from '../search-page/search-page.helpers';
import GmapsWrapper from '../gmaps/loaders/GmapsWrapper';
import { GoogleMapsInitStatus } from '../gmaps/loaders/GoogleMapsApiLoader';
import { resolveAppHref } from '../AppLink';
import { gtmDataLayer } from '@/components/analytics/gtm-events';

const times24h = generateTimeArr('00:00', '23:00');
const autocompletePlaceTypes = ['geocode'];
const autocompleteFields = ['geometry'];
const DATE_FORMAT = templates.date;

type HeaderItemLayoutProps = {
  icon: IconProp;
  title: string;
  body?: any;
  style?: CSSProperties;
  className?: string;
};

const SearchBarItem = ({
  icon,
  title,
  body,
  className,
  style
}: HeaderItemLayoutProps) => {
  return (
    <div
      className={`d-flex align-items-center my-0 hover-pointer ${className}`}
      style={style}
    >
      <div className="mr-3">
        <FontAwesomeIcon icon={icon} size="2x" className="text-primary-light" />
      </div>
      <div className="flex-fill">
        <div className="font-weight-500 text-nowrap">{title}</div>
        <div className="position-relative">{body}</div>
      </div>
    </div>
  );
};

const Divider = () => (
  <>
    <div />
    <style jsx>
      {`
        div {
          display: none;
        }

        @media screen and (min-width: 768px) {
          div {
            display: block;
            background-color: #dcdcdc;
            height: 40px;
            margin: auto;
            width: 1px;
          }
        }
      `}
    </style>
  </>
);

type NotNativeSelectProps = {
  children: React.ReactNode;
  value: string;
  placeholder?: React.ReactText | React.ReactNode;
  togglerClassNames?: string;
  dropdownClassNames?: string;
  height?: string | number;
  scrollToElementWhenNoValue?: string;
};

const NotNativeSelect = forwardRef(
  (
    {
      placeholder = '',
      togglerClassNames = '',
      dropdownClassNames = '',
      height = 150,
      value,
      scrollToElementWhenNoValue,
      children
    }: NotNativeSelectProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const toggleRef = useRef<any>();

    useEffect(() => {
      // scroll to N element on mount
      if (
        scrollToElementWhenNoValue &&
        !value &&
        dropdownOpen &&
        toggleRef.current
      ) {
        const list = toggleRef.current.parentElement.children[1];
        if (list) {
          const listItems = list.getElementsByTagName('button');
          const target = Array.from<HTMLButtonElement>(listItems).find(
            (v) => v.textContent === scrollToElementWhenNoValue
          );
          if (target) {
            list.scrollTop = target.offsetTop;
          }
        }
      }
    }, [dropdownOpen, value, toggleRef, scrollToElementWhenNoValue]);

    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="button"
          type="button"
          className={`text-dark text-left form-control ${togglerClassNames}`}
          innerRef={(tgl: HTMLButtonElement) => {
            toggleRef.current = tgl;
            // @ts-expect-error ref call
            ref(tgl);
          }}
        >
          {value || placeholder}
        </DropdownToggle>
        <DropdownMenu
          persist
          className={`p-0 mt-1 shadow-sm overflow-y-scroll ${dropdownClassNames}`}
          style={{ maxHeight: height, minHeight: 50 }}
        >
          {children}
        </DropdownMenu>
      </Dropdown>
    );
  }
);

const SearchBarTimePicker = forwardRef(
  (
    {
      selectedTime,
      onSelectTime,
      timeArr,
      scrollToElementWhenNoValue = '08:00'
    }: any,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <NotNativeSelect
        ref={ref}
        value={selectedTime}
        placeholder="Time"
        height={230}
        dropdownClassNames="minw-100 minw-md-160"
        togglerClassNames="position-relative"
        scrollToElementWhenNoValue={scrollToElementWhenNoValue}
      >
        {timeArr.map((t) => (
          <DropdownItem
            key={t}
            className={`px-3 ${
              t === selectedTime ? 'text-white bg-primary-light' : ''
            }`}
            onClick={() => onSelectTime(t)}
          >
            {t}
          </DropdownItem>
        ))}
      </NotNativeSelect>
    );
  }
);

type PlaceResultWithInput = google.maps.places.PlaceResult & {
  autocompleteInput: string;
};

const SearchBarSchema = object({
  city: mixed<PlaceResultWithInput>().test(
    'test autocomplete',
    'gmaps-pass',
    (v) => !!v
  ),
  fromDate: date().required(),
  fromTime: string().required(),
  toDate: date().required(),
  toTime: string().required()
});

type SearchBarFormData = {
  city: PlaceResultWithInput;
  fromDate: Date;
  fromTime: string;
  toDate: Date;
  toTime: string;
};

const onSubmit = (data: SearchBarFormData) => {
  const { north, east, west } = data.city.geometry.viewport.toJSON();
  const placeLat = data.city.geometry.location.lat();
  const placeLng = data.city.geometry.location.lng();

  const [fromHours, fromMinutes] = data.fromTime.split(':');
  const [toHours, toMinutes] = data.toTime.split(':');
  const from = toISOLocal(
    data.fromDate,
    parseInt(fromHours, 10),
    parseInt(fromMinutes, 10)
  );
  const to = toISOLocal(
    data.toDate,
    parseInt(toHours, 10),
    parseInt(toMinutes, 10)
  );

  const queryParams = {
    pivotLat: placeLat,
    pivotLng: placeLng,
    aroundLat: placeLat,
    aroundLng: placeLng,
    aroundRadiusInKm:
      calculateAroundRadius([placeLat, placeLng], [north, east]) + 5,
    zoom: getZoomLevelFromBounds(east, west) - 1,
    from,
    to
  };

  gtmDataLayer.homeSearch({
    searchInputValue: data.city.autocompleteInput,
    from,
    to
  });

  Router.push(
    resolveAppHref({ pathname: '/search', query: queryParams }, Router.query)
  );
};

const parseDate = (str: string, format: string) => {
  const result = dayjs(str, format);
  return result.isValid() ? result.toDate() : undefined;
};

const formatDateProp = (date: Date, format: string) => {
  return dayjs(date).format(format);
};

const SearchBar = () => {
  const { handleSubmit, control, watch } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(SearchBarSchema) as any
  });

  const fromDateRef = useRef<HTMLInputElement>();
  const toDateRef = useRef<HTMLInputElement>();

  const fromTimeRef = useRef<HTMLButtonElement>();
  const toTimeRef = useRef<HTMLButtonElement>();

  const submitRef = useRef<HTMLButtonElement>();

  const today = new Date();
  const times24ForToday = times24h.filter((v) => {
    return today.getHours() < parseInt(v.slice(0, 2));
  });
  const mustShowNextDateNotToday = times24ForToday.length === 0;

  const fromDatePlaceholder = formatDate(
    mustShowNextDateNotToday ? dayjs().add(1, 'day') : today,
    'date'
  );
  const toDatePlaceholder = formatDate(dayjs().add(4, 'day'), 'date');

  const { fromDate, fromTime, toDate } = watch();

  useEffect(() => {
    if (fromTime) {
      toDateRef.current?.focus();
    }
  }, [fromTime]);

  const renderSearchBarAutocomplete = (autocomplete: React.ReactElement) => {
    return (
      <SearchBarItem
        icon={['fas', 'map-marker-alt']}
        title="Location"
        className="location-wrapper pr-2 pl-2 pl-lg-3 py-2"
        body={autocomplete}
      />
    );
  };

  const autocompleteRenderer = (status: GoogleMapsInitStatus) => {
    if (status === GoogleMapsInitStatus.SUCCESS) {
      return (
        <Controller
          name="city"
          control={control}
          render={({ field: { onChange, ref } }) =>
            renderSearchBarAutocomplete(
              <NewAutoComplete
                ref={ref}
                types={autocompletePlaceTypes}
                fields={autocompleteFields}
                placeholder="Enter location"
                className="w-100 form-control cursor-pointer"
                // eslint-disable-next-line react-hooks/rules-of-hooks
                onPlaceSelected={useCallback(
                  (place, autocompleteInput) => {
                    onChange({ ...place, autocompleteInput });
                    fromDateRef.current?.focus();
                  },
                  [onChange]
                )}
              />
            )
          }
        />
      );
    }

    if (status === GoogleMapsInitStatus.FAILURE) {
      return renderSearchBarAutocomplete(
        <Input
          placeholder="Failed to load GMaps API"
          className="w-100 cursor-pointer"
          disabled
        />
      );
    }

    return renderSearchBarAutocomplete(
      <Input placeholder="Enter location" className="w-100 cursor-pointer" />
    );
  };

  const renderMainMarkup = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column flex-lg-row"
      >
        <div className="d-flex flex-column flex-md-row flex-fill">
          <div className="d-flex px-1" style={{ flex: 1 }}>
            <label className="mb-0 w-100">
              <GmapsWrapper render={autocompleteRenderer} />
            </label>
          </div>
          <Divider />
          <div className="d-flex px-1" style={{ flex: 1 }}>
            <div style={{ flex: 5 }}>
              <label className="mb-0 w-100">
                <Controller
                  name="fromDate"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, ref } }) => (
                    <SearchBarItem
                      icon={['far', 'calendar-alt']}
                      title="Pick-up date"
                      className="p-2 rounded-lg"
                      body={
                        <DayPickerInput
                          onDayChange={(day) => {
                            if (day) {
                              onChange(day);
                            }
                          }}
                          parseDate={parseDate}
                          formatDate={formatDateProp}
                          format={DATE_FORMAT}
                          placeholder={fromDatePlaceholder}
                          inputProps={{
                            id: 'fromDateInput',
                            className:
                              'w-100 form-control cursor-pointer reset-bg',
                            autoComplete: 'off',
                            ref: (r) => {
                              ref(r);
                              fromDateRef.current = r;
                            },
                            readOnly: true
                          }}
                          dayPickerProps={{
                            onDayClick: () => {
                              setTimeout(() => {
                                fromTimeRef.current?.focus();
                                fromTimeRef.current?.click();
                              }, 100);
                            },
                            disabledDays: [
                              {
                                before: mustShowNextDateNotToday
                                  ? dayjs().add(1, 'day').toDate()
                                  : today
                              }
                            ],
                            modifiers: {
                              today,
                              fromDate: fromDate,
                              toDate: toDate
                            },
                            fromMonth: today,
                            showOutsideDays: true,
                            selectedDays: [
                              fromDate,
                              { from: fromDate, to: toDate }
                            ]
                          }}
                        />
                      }
                    />
                  )}
                />
              </label>
            </div>
            <label
              style={{
                flex: 2,
                ...(fromDate
                  ? {}
                  : {
                      position: 'absolute',
                      visibility: 'hidden',
                      zIndex: -99999
                    })
              }}
              className="hover-pointer d-flex rounded-lg p-2 m-0"
            >
              <div className="mt-auto w-100">
                <Controller
                  name="fromTime"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <SearchBarTimePicker
                      timeArr={
                        isSameDate(fromDate, today) && !mustShowNextDateNotToday
                          ? times24ForToday
                          : times24h
                      }
                      ref={(r) => {
                        const realRef = r;
                        if (realRef) {
                          realRef.onfocus = (ev) => {
                            if (ev.relatedTarget === submitRef?.current) {
                              realRef.click();
                            }
                          };
                          ref(realRef);
                          fromTimeRef.current = realRef;
                        }
                      }}
                      selectedTime={value}
                      onSelectTime={onChange}
                    />
                  )}
                />
              </div>
            </label>
          </div>
          <Divider />
          <div className="d-flex px-1" style={{ flex: 1 }}>
            <div style={{ flex: 5 }}>
              <label className="mb-0 w-100">
                <SearchBarItem
                  icon={['far', 'calendar-alt']}
                  title="Return date"
                  className="p-2 rounded-lg"
                  body={
                    <Controller
                      name="toDate"
                      control={control}
                      defaultValue={null}
                      render={({ field: { onChange, ref } }) => (
                        <DayPickerInput
                          onDayChange={(day) => {
                            if (day) {
                              onChange(day);
                            }
                          }}
                          parseDate={parseDate}
                          formatDate={formatDateProp}
                          format={DATE_FORMAT}
                          placeholder={toDatePlaceholder}
                          inputProps={{
                            id: 'toDateInput',
                            className:
                              'w-100 form-control cursor-pointer reset-bg',
                            autoComplete: 'off',
                            ref: (r) => {
                              ref(r);
                              toDateRef.current = r;
                            },
                            readOnly: true
                          }}
                          dayPickerProps={{
                            onDayClick: () => {
                              setTimeout(() => {
                                toTimeRef.current?.focus();
                                toTimeRef.current?.click();
                              }, 100);
                            },
                            showOutsideDays: true,
                            disabledDays: [
                              {
                                before: mustShowNextDateNotToday
                                  ? dayjs().add(1, 'day').toDate()
                                  : today
                              },
                              {
                                before: fromDate,
                                after: fromDate
                                  ? dayjs(fromDate).add(3, 'month').toDate()
                                  : undefined
                              }
                            ],
                            selectedDays: [
                              fromDate,
                              { from: fromDate, to: toDate }
                            ],
                            month: fromDate,
                            fromMonth: fromDate,
                            modifiers: {
                              today,
                              fromDate: fromDate,
                              toDate: toDate
                            }
                          }}
                        />
                      )}
                    />
                  }
                />
              </label>
            </div>
            <label
              style={{
                flex: 2,
                ...(toDate
                  ? {}
                  : {
                      position: 'absolute',
                      visibility: 'hidden',
                      zIndex: -99999
                    })
              }}
              className="hover-pointer rounded-lg d-flex p-2 m-0"
            >
              <div className="mt-auto w-100">
                <Controller
                  name="toTime"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <SearchBarTimePicker
                      timeArr={times24h}
                      ref={(r) => {
                        const realRef = r;
                        if (realRef) {
                          realRef.onfocus = (ev) => {
                            if (ev.relatedTarget === submitRef?.current) {
                              realRef.click();
                            }
                          };
                          ref(realRef);
                          toTimeRef.current = realRef;
                        }
                      }}
                      selectedTime={value}
                      onSelectTime={onChange}
                    />
                  )}
                />
              </div>
            </label>
          </div>
        </div>
        <button
          ref={submitRef}
          type="submit"
          className="btn btn-primary-light mt-1 mt-lg-0 ml-lg-1 text-nowrap bg-primary-light"
          onClick={handleSubmit(onSubmit)}
        >
          Search
        </button>
        <style jsx>
          {`
            @media screen and (min-width: 992px) {
              button[type='submit'] {
                width: 78px;
                height: 78px;
                border-radius: 50%;
              }
            }
          `}
        </style>
      </form>
    );
  };

  return (
    <>
      <Head>
        <style>
          {`
          .DayPickerInput-Overlay {
            margin-top: 2px;
          }
          @media screen and (max-width: 400px) {
            .DayPickerInput-OverlayWrapper {
              margin-left: -53px;
            }
          }
          @media screen and (min-width: 768px) and (max-width: 992px) {
            .DayPickerInput-OverlayWrapper {
              margin-left: -90px;
            }
          }
          .DayPicker-Day--selected:not(.DayPicker-Day--fromDate):not(.DayPicker-Day--toDate):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
            border-radius: 0 !important;
          }
          .DayPicker-Day--fromDate {
            border-radius: 50% 0 0 50% !important;
          }
          .DayPicker-Day--toDate {
            border-radius: 0 50% 50% 0 !important;
          }

          .location-wrapper {
            border-radius: 0.3rem;
          }
          @media screen and (min-width: 992px) {
            .location-wrapper {
              border-top-left-radius: 47px;
              border-bottom-left-radius: 47px;
            }
          }
         `}
        </style>
      </Head>
      <div className="form-wrapper bg-white shadow-lg mx-auto p-2">
        {renderMainMarkup()}
      </div>
      <style jsx>{`
        .form-wrapper {
          border-radius: 0.3rem;
        }
        @media screen and (min-width: 992px) {
          .form-wrapper {
            border-radius: 47px;
          }
        }
      `}</style>
    </>
  );
};

export default SearchBar;
