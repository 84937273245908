import { Container } from 'reactstrap';
import SearchBar from './SearchBar';

type Props = {
  title: string;
};

const Header = ({ title }: Props) => {
  return (
    <section className="bg-white city-header">
      <div className="landing-svgbg" />
      <Container>
        <h1 className="font-b2w text-primary text-center px-sm-4">
          {title}
          <style jsx>
            {`
              h1 {
                margin-bottom: 20px;
                font-size: 2.2rem;
                margin-top: 25px;
              }
              @media screen and (min-width: 768px) {
                h1 {
                  white-space: nowrap;
                  margin-top: 15%;
                }
              }
              @media screen and (min-width: 992px) {
                h1 {
                  font-size: 3rem;
                  margin-top: 14vw;
                }
              }
              @media screen and (min-width: 1368px) {
                h1 {
                  margin-top: 20%;
                }
              }
            `}
          </style>
        </h1>
        <div>
          <SearchBar />
          <style jsx>
            {`
              div {
                max-width: 950px;
                margin-bottom: 52vw;
                margin-left: auto;
                margin-right: auto;
              }
              @media screen and (min-width: 576px) {
                div {
                  margin-bottom: 30%;
                }
              }
              @media screen and (min-width: 768px) {
                div {
                  margin-bottom: 28vw;
                }
              }
              @media screen and (min-width: 992px) {
                div {
                  margin-bottom: 33vw;
                }
              }
              @media screen and (min-width: 1368px) {
                div {
                  margin-bottom: 45%;
                }
              }
            `}
          </style>
        </div>
      </Container>
    </section>
  );
};

export default Header;
