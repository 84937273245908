import DayPickerInputLib from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import useTranslation from 'next-translate/useTranslation';
import { ForwardedRef, forwardRef } from 'react';

type Props = DayPickerInputProps;

const DayPickerInput = forwardRef(
  (props: Props, ref: ForwardedRef<DayPickerInputLib>) => {
    const { t, lang } = useTranslation('months');

    return (
      <DayPickerInputLib
        {...props}
        ref={ref}
        dayPickerProps={{
          ...props.dayPickerProps,
          locale: lang,
          months: t('asList', {}, { returnObjects: true }),
          weekdaysLong: t('weeksLong', {}, { returnObjects: true }),
          weekdaysShort: t('weeksShort', {}, { returnObjects: true })
        }}
      />
    );
  }
);

export default DayPickerInput;
